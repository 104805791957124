import * as directives from 'vuetify/directives'
import * as labsComponents from 'vuetify/labs/components'

export const isDev = false
export function vuetifyConfiguration() {
  const options = {"ssr":true,"locale":{"locale":"ru"}}
  options.directives = directives
  options.components = labsComponents
  return options
}
