<script setup lang="ts">

</script>

<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M19.636 4.36248C17.5961 2.32263 14.884 1.19922 11.9992 1.19922C9.11444 1.19922 6.40233 2.32263 4.36248 4.36248C2.32263 6.40233 1.19922 9.11444 1.19922 11.9993C1.19922 14.884 2.32263 17.5961 4.36248 19.636C6.40233 21.6758 9.11444 22.7992 11.9992 22.7992C14.884 22.7992 17.5961 21.6758 19.636 19.636C21.6758 17.5961 22.7992 14.884 22.7992 11.9993C22.7992 9.11444 21.6758 6.40233 19.636 4.36248ZM3.69794 16.3196C3.0221 15.0263 2.6392 13.5568 2.6392 11.9993C2.6392 10.4417 3.0221 8.97214 3.6979 7.67888L7.13264 9.39629C6.71599 10.1722 6.4792 11.0586 6.4792 11.9993C6.4792 12.9399 6.71599 13.8263 7.13268 14.6023L3.69794 16.3196ZM11.9992 2.63921C13.5567 2.63921 15.0263 3.0221 16.3196 3.6979L14.6021 7.13264C13.8262 6.716 12.9399 6.4792 11.9992 6.4792C11.0585 6.4792 10.1722 6.716 9.39621 7.13268L7.6788 3.69794C8.97214 3.0221 10.4417 2.63921 11.9992 2.63921ZM16.0792 11.9993C16.0792 14.249 14.2489 16.0792 11.9992 16.0792C9.74953 16.0792 7.91922 14.2489 7.91922 11.9993C7.91922 9.74953 9.74953 7.91923 11.9992 7.91923C14.249 7.91923 16.0792 9.74953 16.0792 11.9993ZM11.9992 21.3593C10.4417 21.3593 8.97214 20.9764 7.67888 20.3006L9.39629 16.8658C10.1722 17.2825 11.0585 17.5193 11.9992 17.5193C12.9399 17.5193 13.8262 17.2825 14.6022 16.8658L16.3196 20.3005C15.0263 20.9764 13.5567 21.3593 11.9992 21.3593ZM16.8658 14.6023C17.2824 13.8263 17.5192 12.94 17.5192 11.9993C17.5192 11.0585 17.2824 10.1722 16.8658 9.39621L20.3005 7.6788C20.9763 8.9721 21.3592 10.4417 21.3592 11.9992C21.3592 13.5567 20.9763 15.0263 20.3005 16.3195L16.8658 14.6023Z" fill="#2A2536"/>
	</svg>
</template>

<style scoped lang="scss">

</style>