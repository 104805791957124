<script setup lang="ts">

</script>

<template>
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_1212_299-360871)">
			<path d="M11 5C10.2583 5 9.5333 5.21993 8.91661 5.63199C8.29993 6.04404 7.81928 6.62971 7.53545 7.31494C7.25162 8.00016 7.17736 8.75416 7.32206 9.4816C7.46675 10.209 7.8239 10.8772 8.34835 11.4017C8.8728 11.9261 9.541 12.2833 10.2684 12.4279C10.9958 12.5726 11.7498 12.4984 12.4351 12.2145C13.1203 11.9307 13.706 11.4501 14.118 10.8334C14.5301 10.2167 14.75 9.4917 14.75 8.75C14.75 7.75544 14.3549 6.80161 13.6517 6.09835C12.9484 5.39509 11.9946 5 11 5ZM11 11C10.555 11 10.12 10.868 9.75 10.6208C9.38 10.3736 9.0916 10.0222 8.92127 9.611C8.75098 9.1999 8.70642 8.7475 8.79323 8.31105C8.88005 7.87459 9.0943 7.47368 9.409 7.15901C9.7237 6.84434 10.1246 6.63005 10.561 6.54323C10.9975 6.45642 11.4499 6.50097 11.861 6.67127C12.2722 6.84157 12.6236 7.12996 12.8708 7.49997C13.118 7.86998 13.25 8.30499 13.25 8.75C13.2494 9.3466 13.0122 9.9185 12.5903 10.3403C12.1685 10.7622 11.5966 10.9994 11 11Z" fill="#2A2536"/>
			<path d="M11 0.5C8.9233 0.5 6.89323 1.11581 5.16652 2.26957C3.4398 3.42332 2.09399 5.0632 1.29927 6.98182C0.504552 8.90045 0.296612 11.0116 0.701762 13.0484C1.1069 15.0852 2.10693 16.9562 3.57538 18.4246C5.04383 19.8931 6.91476 20.8931 8.95156 21.2982C10.9884 21.7034 13.0996 21.4955 15.0182 20.7007C16.9368 19.906 18.5767 18.5602 19.7304 16.8335C20.8842 15.1068 21.5 13.0767 21.5 11C21.4968 8.2162 20.3896 5.54733 18.4211 3.57889C16.4527 1.61044 13.7838 0.50318 11 0.5ZM6.5 18.7828V17.75C6.5006 17.1534 6.73784 16.5815 7.15967 16.1597C7.5815 15.7378 8.15345 15.5006 8.75 15.5H13.25C13.8466 15.5006 14.4185 15.7378 14.8403 16.1597C15.2622 16.5815 15.4994 17.1534 15.5 17.75V18.7828C14.1344 19.5802 12.5814 20.0004 11 20.0004C9.4186 20.0004 7.86563 19.5802 6.5 18.7828ZM16.994 17.6945C16.9791 16.7107 16.5782 15.7722 15.8779 15.0812C15.1776 14.3901 14.2339 14.0019 13.25 14H8.75C7.76614 14.0019 6.82238 14.3901 6.12207 15.0812C5.42176 15.7722 5.02095 16.7107 5.006 17.6945C3.64593 16.4801 2.6868 14.8812 2.25563 13.1095C1.82446 11.3378 1.94158 9.477 2.59148 7.77339C3.24139 6.06978 4.39342 4.60375 5.89503 3.56942C7.39665 2.5351 9.177 1.98127 11.0004 1.98127C12.8237 1.98127 14.6041 2.5351 16.1057 3.56942C17.6073 4.60375 18.7594 6.06978 19.4093 7.77339C20.0592 9.477 20.1763 11.3378 19.7451 13.1095C19.314 14.8812 18.354 16.4801 16.994 17.6945Z" fill="#2A2536"/>
		</g>
		<defs>
			<clipPath id="clip0_1212_299-360871">
				<rect width="22" height="22" fill="white"/>
			</clipPath>
		</defs>
	</svg>
</template>

<style scoped lang="scss">

</style>