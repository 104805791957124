<script setup lang="ts">

</script>

<template>
	<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.88595C0 3.09693 3.10038 0 6.89362 0C8.91634 0 10.747 0.85127 12 2.25509C13.253 0.85127 15.0837 0 17.1064 0C20.8996 0 24 3.09693 24 6.88595C24 8.6464 23.1963 10.3351 22.0964 11.8334C20.9906 13.3396 19.5329 14.7249 18.1021 15.8943C16.6674 17.0668 15.2366 18.0409 14.1665 18.7211C13.6308 19.0617 13.1836 19.3297 12.8692 19.5133C12.712 19.605 12.5878 19.6758 12.5023 19.7239C12.4595 19.748 12.4263 19.7665 12.4035 19.7791L12.3772 19.7936L12.3699 19.7976L12.3678 19.7987C12.3676 19.7989 12.3668 19.7993 12 19.1276C11.6309 19.798 11.6307 19.7979 11.6305 19.7978L11.6277 19.7963L11.6205 19.7923L11.5942 19.7777C11.5714 19.765 11.5383 19.7464 11.4955 19.7221C11.41 19.6736 11.2859 19.6024 11.1287 19.51C10.8144 19.3251 10.3674 19.0554 9.83172 18.7128C8.7619 18.0288 7.33143 17.05 5.89714 15.8745C4.46665 14.7021 3.00941 13.3156 1.90397 11.8124C0.804793 10.3177 0 8.6349 0 6.88595ZM12 19.1276L11.6305 19.7978L11.9985 20L12.3668 19.7993L12 19.1276ZM12.0014 18.2472C12.0314 18.2299 12.063 18.2115 12.0962 18.1921C12.3962 18.017 12.8267 17.759 13.3441 17.4301C14.3804 16.7714 15.7581 15.8328 17.132 14.71C18.5096 13.5841 19.8604 12.2914 20.8611 10.9285C21.8675 9.5576 22.4681 8.186 22.4681 6.88595C22.4681 3.94204 20.0536 1.53021 17.1064 1.53021C15.2222 1.53021 13.5772 2.46885 12.6462 3.93025L12 4.9446L11.3538 3.93025C10.4228 2.46885 8.77776 1.53021 6.89362 1.53021C3.94643 1.53021 1.53191 3.94204 1.53191 6.88595C1.53191 8.172 2.13138 9.5368 3.13859 10.9065C4.13952 12.2676 5.4908 13.5621 6.86882 14.6915C8.24304 15.8177 9.62108 16.7612 10.6576 17.4241C11.1752 17.755 11.6058 18.0148 11.9058 18.1912C11.9394 18.211 11.9713 18.2296 12.0014 18.2472Z" fill="#2A2536"/>
	</svg>
</template>

<style scoped lang="scss">

</style>